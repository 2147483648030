<template>
  <div>
    <b-card>
      <form @submit.prevent="Update" @keydown="form.onKeydown($event)">
        <div class="row">
          <div class="col-md-12">
            <div class="border rounded p-2 mb-2">
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <img
                    :src="image"
                    width="208"
                    height="91"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-form-group label-for="image">
                    <b-form-file
                      id="image"
                      accept=".jpg, .png, .gif"
                      @change="loadImage($event)"
                      placeholder="Choose a partner logo ( Size - 160px X 70px )"
                      drop-placeholder="Drop file here..."
                    />
                    <has-error :form="form" field="image"></has-error>
                  </b-form-group>
                  <div class="w-100 text-right">
                    <b-button type="submit" variant="primary"> Add </b-button>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </div>
        </div>
      </form>
    </b-card>
    <b-card>
      <b-card-title>Partner Lists</b-card-title>
      <div class="row justify-content-center">
        <div
          class="partner_image m-1"
          style="max-width: 160px"
          v-for="(partner, index) in partners"
          :key="index"
        >
          <div class="image_delete" @click="Detele(partner.id)"><b>X</b></div>
          <img :src="$store.state.base_url + partner.logo" alt="" />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
export default {
  data() {
    return {
      form: new Form({
        image: null,
      }),
      partners: "",
      image: this.getImage(160, 70),
      required,
      email,
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    async getResults() {
      const partners = await this.callApi("get", "/app/cms/partner");
      this.partners = partners.data.partner;
    },

    loadImage(e) {
      this.form.image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      this.form
        .post("/app/cms/partner")
        .then(({ data }) => {
          this.getResults();
          this.s(data.message);
          this.$Loading.finish();
        })
        .catch((e) => {
          this.$Loading.error();
        });
    },

    Detele(partnerId) {
      axios.post("/app/cms/partner/" + partnerId).then((res) => {
        this.s(res.data.message);
        this.getResults();
      });
    },
  },
  components: {
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
  },
};
</script>

<style>
.partner_image {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.partner_image img {
  width: 100%;
  height: 100%;
}
.image_delete {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  background: #aeaeae;
  position: absolute;
  right: 5%;
  top: 5%;
  cursor: pointer;
  color: #fff !important ;
  font-size: 16px;
  line-height: 28px;
}
</style>
